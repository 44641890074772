import React, { useState } from 'react';
import { Brand } from '../../../ts/enums/Colors';
import DataCell from '../../atoms/DataCell';
import {
  CircleMinus,
  CirclePlus,
} from '../../atoms/Icons';
import LinkCell from '../../atoms/LinkCell';
import { ICheckList } from '../../../ts/interfaces/molecules/CheckLists';
import CheckListActions from './CheckListActions';
import ImageCell from '../../atoms/ImageCell';

/**
 * Component that renders the Insight Items for the payroll
 * section of the Period Close page
 * @param props - the props of the component
 * @returns
 */
const PayrollCheckList = ({
  tables,
  title,
  checkListIdentifier,
  selectedPeriod,
  defaultOpen,
  itemId,
}: ICheckList) => {
  const [isOpened, setOpened] = useState<boolean>(defaultOpen);

  /**
   * Function to toggle the state of the component
   */
  function openSection() {
    if (isOpened) {
      setOpened(false);
    } else {
      setOpened(true);
    }
  }

  /**
   * Function to return the appropriate class name for the cell
   * based on its cellType
   * @param cellType
   * @returns
   */
  function cellStyle(cellType) {
    switch (cellType) {
      case 'header':
        return 'FieldLabel';
      case 'subheader':
        return 'FieldLabel';
      case 'separated':
        return 'p-small';
      default:
        return '';
    }
  }

  /**
   * Function to return the appropriate spacing class name for the component
   * based on its index and open state
   * @param ix - the index of the component
   * @returns
   */
  function collapseHandler(ix) {
    switch (ix) {
      case 0:
        return isOpened ? 'mt-4' : 'mt-0';
      default:
        return isOpened ? 'mt-2' : 'mt-0';
    }
  }

  /**
   * Function to return the appropriate cell component based on the cellType
   * @param cell - The data to be displayed in the cell
   * @param index - The index of the component
   * @param rowType - The type of row the cell is in
   * @returns
   */
  function populateCell(cell, index, rowType) {
    if (cell.cellType === 'link') {
      return (
        <LinkCell
          key={`PAYROLL_LINKCELL-${cell.cellType}-${index * Math.random() * 1000000}`}
          data={cell.data}
          link={cell.link}
          index={index}
          size={[0, 1, 2].includes(index) ? 'col-3' : 'col-2'}
          alignment={[0, 3].includes(index) ? 'text-left' : 'text-right'}
          cellStyle={cellStyle(rowType)}
        />
      );
    }
    if (cell.cellType === 'image') {
      return (
        <ImageCell
          key={`PAYROLL_IMAGECELL-${cell.cellType}-${index * Math.random() * 1000000}`}
          data={cell.data}
          link={cell.link}
          index={index}
          size='col-2'
          cellStyle={cellStyle(rowType)}
          alignment='text-center'
        />
      );
    }
    let align = index === 0 ? 'text-left' : 'text-right';

    if (itemId === 'wagesComparison' && [4, 3].includes(index)) align = 'text-center';

    return (
      <DataCell
        key={`PAYROLL_DATACELL-${cell.cellType}-${index * Math.random() * 1000000}`}
        data={cell.data}
        prefix={cell.prefix}
        postfix={cell.postfix}
        index={index}
        size='col-2'
        alignment={align}
        cellStyle={cellStyle(rowType)}
      />
    );
  }

  return (
    <div className='dataHealthChecklistContainer'>
      <div className='dataHealthChecklistHeader'>
        {/* eslint-disable-next-line max-len  */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={openSection}
          data-testid={isOpened ? 'circle-minus-icon' : 'circle-plus-icon'}
        >
          {isOpened ? (
            <CircleMinus color={Brand.blue} />
          ) : (
            <CirclePlus color={Brand.blue} />
          )}
          <span
            className='TextCTA'
            style={{ marginLeft: 8 }}
          >
            {title}
          </span>
        </div>

        <CheckListActions
          checkListIdentifier={checkListIdentifier}
          selectedPeriod={selectedPeriod}
          title={title}
        />
      </div>
      {tables?.tableRows
        && tables.tableRows.map((table, index) => (
          <div
            key={`payrollChecklistTable${index * Math.random() * 999999}`}
            className={`collapseCheckList ${
              isOpened ? 'opened' : ''
            } container ${collapseHandler(index)} mr-0`}
          >
            {index > 0 ? <hr className='mt-2 mb-2' /> : null}
            <div
              className='row mb-1'
              role='row'
            >
              {table.rowCells.map((cell, inx) => populateCell(cell, inx, table.rowType))}
            </div>
          </div>
        ))}
    </div>
  );
};

PayrollCheckList.defaultProps = {
  defaultOpen: false,
};

export default PayrollCheckList;
