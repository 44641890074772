import { InsightTab } from '../../stores/v1/pageStore';

export enum AccountSettings {
  USERRPROFILE = 'My Profile',
  FIRM = 'Firm Information',
  BRAND = 'Manage Branding',
  ADVISORS = 'Manage Advisors',
  PERMISSIONS = 'Manage Permissions',
  CHECKLIST_TEMPLATES = 'Period Close Templates',
  SUBSCRIPTION = 'Manage Subscription',
  ASSISTANT = 'My AI Assistant Settings',
}

export enum DashboardColumnInsightMap {
  'unreconciled' = InsightTab.dataCompliance,
  'revenue' = InsightTab.profitability,
  'netProfit' = InsightTab.profitability,
  'incometax' = InsightTab.taxAndCompliance,
  'cashflow' = InsightTab.cashflow,
  'gstcompletion' = InsightTab.taxAndCompliance,
  'gstestimate' = InsightTab.taxAndCompliance,
}

export enum DashboardSortTarget {
  active,
  reviewed,
  name,
  updated,
  status,
  progress
}

export enum DashboardSortDirection {
  ascending,
  descending
}
