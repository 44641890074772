import * as React from 'react';
import { Tooltip } from 'antd';

type Props = {
  data: string,
  link: string,
  index: number,
  size: string,
  cellStyle: string,
  alignment?: string,
};

const ImageCell = ({ index, link, data, size, cellStyle, alignment }: Props) => (
  <div
    key={index}
    className={`mt-2 ${cellStyle} ${alignment} ${size}`}
    role='cell'
  >
    <Tooltip title={data} placement='bottom'>
      <span>
        <img src={link} alt={data} />
      </span>
    </Tooltip>
  </div>
);

ImageCell.defaultProps = { alignment: 'text-left' };

export default ImageCell;
