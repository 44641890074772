import React, { useState } from 'react';
import { HistoryOutlined } from '@ant-design/icons';
import AssistantIntro from './AssistantIntro';
import ChatMessage from './ChatMessage';
import ButtonSecondary from '../customAntD/ButtonSecondary';

const ChatWindow = ({
  chatMessages = [],
  messageHistory = [],
  page = 'insights',
}) => {
  const [historyMessages, setHistoryMessages] = useState([]);
  const [historyCount, setHistoryCount] = useState(0);
  return (
    <div className='assistant-chat-window'>
      <AssistantIntro page={page} />
      {historyCount < messageHistory.length && (
        <div className='assistant-history'>
          <ButtonSecondary
            icon={<HistoryOutlined />}
            onClick={() => {
              const tenOrMoreLeft = messageHistory.length - historyCount >= 10;
              const start = tenOrMoreLeft
                ? messageHistory.length - historyCount - 10
                : 0;
              const end = messageHistory.length - historyCount;
              setHistoryMessages([
                ...messageHistory.slice(start, end),
                ...historyMessages,
              ]);
              setHistoryCount(historyCount + 10);
            }}
            mixpanelEvent='assistant-user__prompt-link'
          >
            Load older messages
          </ButtonSecondary>
        </div>
      )}
      {[...historyMessages, ...chatMessages].map((message) => (
        <ChatMessage
          message={message}
          page={page}
        />
      ))}
    </div>
  );
};

export default ChatWindow;
