import * as React from 'react';
import { getPlotExtremes } from '../../../entities/iconHelper';
import { IconInterface } from '../../../ts/interfaces/atoms/Icons';
/**
 * The arrow up icon. Also known as a "". `↑`
 * @param props
 * @returns
 */
export const ArrowUp = ({
  width = 16,
  height = 16,
  color = 'currentColor',
  strokeWidth = 2,
  fill = 'none',
  lineCap = 'round',
  lineJoin = 'round',
  padding = 0.25,
  ...rest
}: IconInterface) => {
  const { min, max } = getPlotExtremes({ width, height, padding });
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill={fill}
      stroke={color}
      strokeWidth={strokeWidth}
      strokeLinecap={lineCap}
      strokeLinejoin={lineJoin}
      xmlns='http://www.w3.org/2000/svg'
      {...rest}
    >
      <line x1={width / 2} y1={min.y} x2={width / 2} y2={max.y} />
      <polyline
        points={`${min.x},${height / 2} ${width / 2},${min.y} ${max.x},${
          height / 2
        }`}
      />
    </svg>
  );
};
