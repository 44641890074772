import React, { useState } from 'react';
import { Button, Form, Tooltip } from 'antd';
import { CopyOutlined, PlusOutlined } from '@ant-design/icons';
import Markdown from 'markdown-to-jsx';
import { AiderBrandColorful } from '../icons';
import UserIcon from './UserIcon';

import { useStore } from '../../stores/Store';
import SavePromptModal from '../modals/SavePromptModal';

const Links = ({ children, ...props }) => (
  <a {...props} target='_blank'>{children}</a>
);

const ChatMessage = ({ message, page }) => {
  const [copyState, setCopyState] = useState(false);
  const rootStore = useStore();
  const [form] = Form.useForm();

  const copyMessage = () => {
    setCopyState(true);
    navigator.clipboard.writeText(message.content);
    setTimeout(() => {
      setCopyState(false);
    }, 1000);
  };

  return (
    <div className={`chat-message message-type-${message.role}`}>
      {message.role !== 'user' ? (
        <AiderBrandColorful className='aider-icon' />
      ) : (
        <UserIcon />
      )}
      <div className='chat-message__text'>
        <Markdown
          options={{
            overrides: {
              a: {
                component: Links
              },
            },
          }}
        >
          {message.content}
        </Markdown>
      </div>
      {message.role === 'user' ? (
        <Tooltip
          title='Add to My Prompts'
          placement='left'
        >
          <Button
            type='primary'
            ghost
            className='copy-button'
            icon={<PlusOutlined />}
            shape='circle'
            size='large'
            onClick={() => {
              form.setFieldValue('prompt', message.content);
              const prompt = {
                label: '',
                text: message.content,
                page
              };
              return SavePromptModal(rootStore, prompt, form);
            }}
          />
        </Tooltip>
      ) : (
        <Tooltip
          title={copyState ? 'Copied!' : 'Copy to clipboard'}
          placement='left'
        >
          <Button
            type='primary'
            ghost
            className='copy-button'
            icon={<CopyOutlined />}
            shape='circle'
            size='large'
            onClick={copyMessage}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default ChatMessage;
