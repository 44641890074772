import { observer } from 'mobx-react';
import * as React from 'react';
import { Welcome } from '../entities/components';
import {
  constructFields,
  renderDataType,
  renderLowercaseDataType,
  renderUppercaseDataType,
} from '../entities/utils';
import { useStore } from '../stores/Store';
import InsightComponent from '../components-v2/organisms/InsightComponent';
import InsightTabs from '../components-v2/molecules/InsightTabs';
import aiderInfo from '../entities/icons/aiderInfoIcon.svg';
import err from '../entities/icons/err.png';

import HelpContainer from '../components-v2/molecules/HelpContainer';
import Mixpanel from '../lib/mixpanel';
import handleError from '../lib/errorHandler';
import { InsightTab } from '../stores/v1/pageStore';
import InsightsSplash from '../components/splashScreens/InsightsSplash';
import { Routers } from '../models/enums/utils';

export interface IFormState {
  toggledState: any;
  activeLowercaseDataType: string;
  activeUppercaseDataType: string;
  dataType: string;
}
interface IFormProps {
  renderActionCentreModal: any;
  actionCenterItems: any;
  toggleModal: any;
}

const TabManager: React.FC<IFormProps> = (props) => {
  const boxRef = React.useRef<unknown>();
  const defaultTab = InsightTab.overview;
  const [state, setState] = React.useState<IFormState>({
    toggledState: defaultTab,
    activeLowercaseDataType: renderLowercaseDataType(
      defaultTab
    ),
    activeUppercaseDataType: renderUppercaseDataType(
      defaultTab
    ),
    dataType: renderDataType(defaultTab),
  });
  const rootStore = useStore();
  const { resyncStore, loadingStore, practiceStore, businessStore, businessesStore, pageStore, alertStore, actionStore, helpPanelStore } = rootStore;

  const setActiveInsightTab = (tab) => {
    pageStore.setTabActive(tab);

    const eventName = `open_clients${tab}Tab`;
    const eventData = {
      businessId: businessStore.selectedBusinessId,
      businessName: businessStore.selectedBusinessName,
    };
    Mixpanel.track(eventName, eventData);

    let newState = {
      toggledState: '',
      activeLowercaseDataType: '',
      activeUppercaseDataType: '',
      dataType: '',
    };
    switch (tab) {
      case InsightTab.overview:
        newState = {
          toggledState: tab,
          activeLowercaseDataType: 'overview',
          activeUppercaseDataType: 'Overview',
          dataType: 'Overview',
        };
        break;
      case InsightTab.dataCompliance:
        newState = {
          toggledState: tab,
          activeLowercaseDataType: 'data health',
          activeUppercaseDataType: 'Data Health',
          dataType: 'DataHealth',
        };
        break;
      case InsightTab.profitability:
        newState = {
          toggledState: tab,
          activeLowercaseDataType: 'profitability',
          activeUppercaseDataType: 'Profitability',
          dataType: 'Profitability',
        };
        break;
      case InsightTab.cashflow:
        newState = {
          toggledState: tab,
          activeLowercaseDataType: 'cash flow',
          activeUppercaseDataType: 'Cash Flow',
          dataType: 'Cashflow',
        };
        break;
      case InsightTab.taxAndCompliance:
        newState = {
          toggledState: tab,
          activeLowercaseDataType: 'compliance',
          activeUppercaseDataType: 'Compliance',
          dataType: 'Compliance',
        };
        break;
      case InsightTab.report:
        newState = {
          toggledState: tab,
          activeLowercaseDataType: 'report',
          activeUppercaseDataType: 'Report',
          dataType: 'Report',
        };
        break;
      case InsightTab.advisory:
        newState = {
          toggledState: tab,
          activeLowercaseDataType: 'advisory',
          activeUppercaseDataType: 'Advisory',
          dataType: 'Advisory',
        };
        break;
      default:
        newState = {
          toggledState: 'insights',
          activeLowercaseDataType: '',
          activeUppercaseDataType: '',
          dataType: ''
        };
        break;
    }
    setState(newState);
  };

  React.useEffect(() => {
    if (pageStore.tabActive) {
      setActiveInsightTab(pageStore.tabActive);
    }
  }, [pageStore.tabActive, alertStore.activeCount]);

  React.useEffect(() => {
    resyncStore.watchFirestore(Routers.INSIGHTS);
    return resyncStore.unsubscribeDataStatus;
  }, [practiceStore.id, businessesStore.selectedBusinessId]);

  const renderActionCentreModal = (actionCenterItems, type, insight?) => {
    if (insight) {
      actionStore.selectInsightAsAction(insight);
      return props.renderActionCentreModal('insight');
    }

    if (actionCenterItems && actionCenterItems.length >= 1) {
      const selectedAlert = actionCenterItems.find(
        (alert) => alert.subjectKey === type
      );
      actionStore.selectActionCenterItem(selectedAlert);
      return props.renderActionCentreModal({ id: selectedAlert.subjectKey });
    }

    return null;
  };

  // @ts-ignore
  const render = () => {
    const { actionCenterItems } = props;

    // Help panel won't update unless TabManager re-renders
    // And TabManager won't observe visibleHelpItems unless it's referenced in the render function
    // Hence this weird line
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const watchThis = helpPanelStore.visibleHelpItems;

    const businessDetails = businessStore.businessListDetail.find(
      (i) => i.businessId === businessesStore.selectedBusinessId
    );
    const businessListInstance = businessStore.businessListData.find(
      (instance) => instance.id === businessesStore.selectedBusinessId
    );

    constructFields(businessDetails, businessListInstance, false);

    try {
      if (pageStore.toggledModal !== null) {
        // @ts-ignore
        boxRef.current.scrollTop = 0;
      }
    } catch (error) {
      handleError({ error, transaction: 'Tab Manager: scrollToTop' });
    }

    // @ts-ignore
    if (!businessStore.practiceId.id) {
      return null;
    }

    const isLoadingETL = businessesStore.isLoadingETL; // eslint-disable-line

    return loadingStore.isAnyLoading ? null : businessesStore.activeClientBusinesses.size < 1 ? (
      <InsightsSplash />
    ) : (
      <>
        <nav className='dataInsightsNav wrapper wrapper-xl'>
          <InsightTabs
            onClick={setActiveInsightTab}
          />
        </nav>
        <section className='scrollbar'>
          <div
            id='insightContainer'
            className={`wrapper wrapper-xl insightWidth ${state.toggledState !== 'advisory' && 'insightContainerPadding'}`}
            // @ts-ignore
            ref={boxRef}
          >
            {isLoadingETL ? (
              <Welcome
                img={aiderInfo}
                imgWidth={100}
                imgHeight={100}
                body='Please check back later for some valuable insights'
                heading='Syncing and analysing client data'
              />
            ) : businessDetails
              && businessDetails.disconnected
              && !businessStore.insightsLoading
              && !businessStore.checkListLoading
              && state.toggledState !== 'configuration' ? (
                <div
                  className='fadedshort'
                  style={{
                    height: '100%',
                    width: '100%',
                    padding: 200,
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <img
                        width={120}
                        src={err}
                        alt='Error'
                      />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div
                        style={{
                          fontSize: 20,
                          fontWeight: 600,
                          color: '#0D206C',
                          marginTop: 40,
                          marginBottom: 10,
                        }}
                      >
                        {businessDetails.name} is disconnected
                      </div>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      <p style={{ fontSize: 15, width: 400, marginBottom: 20 }}>
                        Please remove this business from your Aider account and
                        reconnect it to Xero to access insights.
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <InsightComponent
                  lowercaseType={state.activeLowercaseDataType}
                  uppercaseType={state.activeUppercaseDataType}
                  type={state.dataType}
                  insightsLoading={businessStore.insightsLoading}
                  insightData={businessStore.insightData}
                  insightSummary={businessStore.insightSummary}
                  renderActionCentreModal={(type, insight) => renderActionCentreModal(
                    actionCenterItems.alerts,
                    type,
                    insight
                  )}
                  toggleModal={props.toggleModal}
                />
              )}
          </div>
          {helpPanelStore.selectedIndex >= 0 && (
            <HelpContainer isClient />
          )}
        </section>
      </>
    );
  };
  return render();
};

const ObservedTabManager = observer(TabManager);
export default ObservedTabManager;
