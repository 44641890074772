import React, { useState } from 'react';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { PracticeTypes } from '@aider/constants-library';
import WysiwygDeleteButton from './WysiwygDeleteButton';
import { WYSIWIG_TOOLBAR_OPTIONS } from '../../models/constants/components';

interface ContentEditorProps {
  content: string;
  onChange: (content: string) => void;
  blockType: PracticeTypes.ReportBlock['type'];
}

const WysiwygEditor: React.FC<ContentEditorProps> = ({ content, onChange, blockType }) => {
  const [state, setState] = useState(
    content ? EditorState.createWithContent(convertFromRaw(content)) : EditorState.createEmpty()
  );

  return (
    <Editor
      editorState={state}
      onEditorStateChange={(newState) => {
        setState(newState);
        onChange(convertToRaw(newState.getCurrentContent()));
      }}
      wrapperClassName='wysiwyg'
      editorClassName='wysiwyg__editor scrollbar'
      toolbarClassName='wysiwyg__toolbar'
      toolbar={
        {
          options: WYSIWIG_TOOLBAR_OPTIONS?.[blockType] || WYSIWIG_TOOLBAR_OPTIONS.text,
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold'],
          },
          blockType: {
            inDropdown: true,
            options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
            className: undefined,
            dropdownClassName: undefined,
          },
          textAlign: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['left', 'center']
          },
          list: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['unordered', 'ordered'],
          },
          link: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            popupClassName: undefined,
            dropdownClassName: undefined,
            showOpenOptionOnHover: true,
            defaultTargetOption: '_blank',
            options: ['link', 'unlink'],
            linkCallback: undefined
          },
          history: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['undo', 'redo'],
          },
        }
      }
      toolbarCustomButtons={[
        <WysiwygDeleteButton />,
      ]}
      localization={{
        locale: 'en',
        translations: {
          'components.controls.blocktype.h1': 'Heading 1',
          'components.controls.blocktype.h2': 'Heading 2',
          'components.controls.blocktype.h3': 'Heading 3',
          'components.controls.blocktype.h4': 'Heading 4',
          'components.controls.blocktype.h5': 'Heading 5',
          'components.controls.blocktype.h6': 'Heading 6',
          'components.controls.blocktype.normal': 'Paragraph',
          'components.controls.link.linkTitle': 'Text to display',
          'components.controls.link.linkTarget': 'Link to',
        }
      }}
    />
  );
};

export default WysiwygEditor;
