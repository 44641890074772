/* eslint-disable no-shadow */
/**
 * The Enum list for possible message mediums.
 * Where a medium is the channel through which a message is sent.
 */

// eslint-disable-next-line no-shadow
export enum MessageMedium {
  email = 'email',
  app = 'app',
}

export enum ErrorMessage {
  PROFITLOST = 'No matching transactions found',
  BALANCESHEET = 'No matching item found'
}
