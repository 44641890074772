import React from 'react';
import { observer } from 'mobx-react';
import AssistantChat from '../../components/AdvisoryAssistant/AssistantChat';

const AdvisoryAi = () => (
  <div className='main'>
    <AssistantChat />
  </div>
);

export default observer(AdvisoryAi);
