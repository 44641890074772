import React from 'react';
import CommentButton from '../../../components/comments/CommentButton';
import RuleCheckListOptions from './RulesCheckListOptions';
import CheckListOptions from './CheckListOptions';
import { useStore } from '../../../stores/Store';
import { ICheckListIdentifier } from '../../../ts/interfaces/molecules/CheckLists';

const CheckListActions = ({
  checkListIdentifier,
  selectedPeriod,
  title,
} : {
  checkListIdentifier: ICheckListIdentifier,
  selectedPeriod: string,
  title: string,
}) => {
  const { commentsStore, checklistStore } = useStore();
  const activeChecklist = checklistStore?.currentChecklistGroups?.tableGroups?.find((item) => item.tableGroupPeriod.periodName === selectedPeriod);
  const periodStart = activeChecklist?.tableGroupPeriod?.periodStart;
  const periodEnd = activeChecklist?.tableGroupPeriod?.periodEnd;
  const checklistType = activeChecklist?.tableGroupType;

  return selectedPeriod
    && (
      <div className='dataHealthChecklist__row--multi-action'>
        <CommentButton
          locationIdentifier={
            commentsStore.getLocationIdentifier(
              checklistType,
              periodStart,
              periodEnd,
              checkListIdentifier.itemId
            )
          }
          title={title}
        />
        <div className='dataHealthChecklist__row--multi-action__block'>
          <RuleCheckListOptions
            checkListIdentifier={checkListIdentifier}
            selectedPeriod={selectedPeriod}
          />
          <CheckListOptions checkListIdentifier={checkListIdentifier} />
        </div>
      </div>
    );
};

export default CheckListActions;
