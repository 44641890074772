import React from 'react';
import { observer } from 'mobx-react';
import { CustomRuleEnums } from '@aider/constants-library';
import Markdown from 'markdown-to-jsx';
import AiderAlert from '../../../components/AiderAlert';
import { useStore } from '../../../stores/Store';

interface INoRuleBannerProps {
  categoryId: string;
  isPractice?: boolean;
}

const NoRuleBanner = ({ categoryId, isPractice }: INoRuleBannerProps) => {
  const { localeStore } = useStore();
  return (
    <aside className={isPractice ? 'practice-checklist__banner' : 'main dataHealthChecklistContainer'}>
      <AiderAlert
        type='info'
        title={isPractice ? undefined : localeStore.translation('period-close.banner.noRules.title', {
          sectionType: localeStore.translation(`period-close.section-header.${categoryId}`),
        })}
      >
        <Markdown>
          {
            isPractice ? (
              localeStore.translation('period-close.banner.noRules.practiceDescription', { extraChecks: CustomRuleEnums.RuleOnlyCategories[categoryId] ? '**Automated Data Checks** and ' : '' })
            ) : (
              localeStore.translation('period-close.banner.noRules.clientDescription')
            )
          }
        </Markdown>
      </AiderAlert>
    </aside>
  );
};

NoRuleBanner.defaultProps = {
  isPractice: false,
};

export default observer(NoRuleBanner);
