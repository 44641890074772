import React from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react';
import { PracticeTypes } from '@aider/constants-library';
import Markdown from 'markdown-to-jsx';
import draftToHtml from 'draftjs-to-html';
import { useStore } from '../../../stores/Store';
import WysiwygEditor from '../WysiwygEditor';
import { trackMixpanelEvent } from '../../../lib/mixpanel';

const TextComponent = ({
  className,
  block
}: {
  className: string,
    block: PracticeTypes.ReportBlock
}) => {
  const rootStore = useStore();
  const { reportTemplateStore } = rootStore;
  const isEditing = reportTemplateStore.editBlock === block.id;
  return (block.content || block.content === '') && (
    <Form.Item
      className={`${className} ${className}--${isEditing ? 'editing' : 'read-only'} ${className}--${block.type}`}
      name={block.id}
      label={null}
      initialValue={block.content}
    >
      {isEditing ? (
        <WysiwygEditor
          blockType={block.type}
          content={block.content}
          onChange={(content) => { // eslint-disable-line @typescript-eslint/no-unused-vars
            // This does nothing other than run the action which allows
            // the form to be updated with the new content. It requires
            // the new value to be passed in, but we don't need to do anything with it.
          }}
        />
      ) : (
        block.content && (
          <Markdown
            onClick={() => {
              trackMixpanelEvent({ description: 'Report Editor - Edit Text', properties: { editBy: 'click' }, rootStore });
              reportTemplateStore.editBlock = block.id;
            }}
          >
            {draftToHtml(block.content)}
          </Markdown>
        )
      )}
    </Form.Item>
  );
};

export default observer(TextComponent);
