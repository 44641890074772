import React, { useState } from 'react';
import { rootStore } from '../../../stores/Store';
import { Brand } from '../../../ts/enums/Colors';
import DataCell from '../../atoms/DataCell';
import {
  CircleMinus,
  CirclePlus,
  ArrowDown,
  ArrowUp,
} from '../../atoms/Icons';
import { InsightRowType, InsightColumnType } from '../../../ts/enums/utils';
import { ErrorMessage } from '../../../ts/enums/MessageMedium';
import { IAffix } from '../../../ts/interfaces/atoms/DataCell';
import { ICheckList } from '../../../ts/interfaces/molecules/CheckLists';
import CheckListActions from './CheckListActions';

/**
 * Component that renders the Insight Items for the Rule Check List
 * section of the Period Close page
 * @returns
 */
const RuleCheckList = ({
  title,
  tables,
  checkListIdentifier,
  selectedPeriod,
  defaultOpen,
}: ICheckList) => {
  const { ruleLoadingMsg } = rootStore.businessStore;
  const { itemId } = checkListIdentifier;
  const [isOpened, setOpened] = useState<boolean>(defaultOpen);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setOpened(!isOpened);
  };

  const matchingItem = ruleLoadingMsg.find(
    (item) => item.id === itemId && item.loadingStatus
  );

  /**
   * Function to return the appropriate class name for the cell
   * based on its cellType
   * @param cellType
   * @returns
   */
  const cellStyle = (rowType: string): string => {
    if (rowType.includes('header')) return 'FieldLabel';
    if (rowType.includes('separated')) return 'p-small';

    return 'p-small p-light';
  };

  /**
   * rowType === 'summary-'
   * col 1: col-6,
   * col 2 / col 3 / col 4: col-2
   *
   * rowType === 'header' || 'separated'
   * col 1: col-2,
   * col 2: col-4,
   * col 3 / col 4: col-2
   *
   * rowType === 'subHeader'
   * col 1: col-6,
   * col 2 / col 3: col-1,
   * col 4: col-4
   *
   */
  const rowStyles = (rowType: string, index: number): string => {
    if (rowType.includes(InsightRowType.SUMMARY)) {
      return index === 0 ? 'col-6' : 'col-2';
    }

    if (
      rowType === InsightRowType.SEPARATED
      || rowType === InsightRowType.HEADER
    ) {
      if (index === 0) return 'col-2';
      if (index === 1) return 'col-4';
      return 'col-2';
    }

    if (rowType === InsightRowType.SUBHEADER) {
      if (index === 0) return 'col-6';
      if (index === 1 || index === 2) return 'col-1';
      return 'col-2';
    }

    return 'col-2';
  };

  const cellAlignment = (cellType: string, index: number): string => {
    const isSummary = cellType.includes(InsightRowType.SUMMARY);
    const isSeparated = cellType === InsightRowType.SEPARATED;
    const isHeader = cellType === InsightRowType.HEADER;
    const isSubheader = cellType === InsightRowType.SUBHEADER;

    if (isSummary && (index === 1 || index === 2)) return 'text-right';
    if ((isSeparated || isHeader) && index === 3) return 'text-right';
    if (isSubheader && index === 3) return 'text-right';

    return 'text-left';
  };

  /**
   * Function to return the appropriate prefix for a cell
   * @param cellType - The cell of the component
   * @param rawData - The direction of the icon
   * @returns IAffix | undefined
   */
  const prefixCell = (
    cellType: string,
    rawData: number
  ): IAffix | undefined => {
    if (cellType !== InsightColumnType.TREND || rawData === 0) {
      return undefined;
    }
    const currentValue =
      rawData > 0 ? (
        <ArrowUp
          style={{ marginBottom: '0.2em' }}
          aria-label='arrow-up'
        />
      ) : (
        <ArrowDown
          style={{ marginBottom: '0.2em' }}
          aria-label='arrow-down'
        />
      );

    return {
      value: currentValue,
      newline: false,
    };
  };

  /**
   * Function to return the appropriate cell component based on the cellType
   * @param cell - The data to be displayed in the cell
   * @param index - The index of the component
   * @param rowType - The type of row the cell is in
   * @returns ReactNode
   */
  const populateCell = (
    cell: any,
    index: number,
    rowType: string
  ): React.ReactNode => (
    <DataCell
      key={`RULE-${cell.cellType}-${index * Math.random() * 1000000}`}
      data={cell.data}
      index={index}
      size={rowStyles(rowType, index)}
      alignment={cellAlignment(rowType, index)}
      cellStyle={cellStyle(rowType)}
      prefix={prefixCell(cell.cellType, cell.rawData)}
    />
  );

  const tableLength = tables.tableRows.length;

  return (
    <div className='dataHealthChecklistContainer generalCheckList'>
      <div className='dataHealthChecklistHeader'>
        {/* eslint-disable-next-line max-len  */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          className='flex--align__center'
          onClick={handleOpen}
          data-testid={isOpened ? 'circle-minus-icon' : 'circle-plus-icon'}
        >
          {isOpened ? (
            <CircleMinus color={Brand.blue} />
          ) : (
            <CirclePlus color={Brand.blue} />
          )}
          <span
            className='TextCTA'
            style={{ marginLeft: 8 }}
          >
            {title}
          </span>
        </div>

        <CheckListActions
          checkListIdentifier={checkListIdentifier}
          selectedPeriod={selectedPeriod}
          title={title}
        />
      </div>
      {tableLength > 0 ? (
        tables.tableRows.map((table, index) => (
          <div
            key={`TABLE-${index * Math.random() * 1000000}`}
            className={`collapseCheckList ${
              isOpened ? 'opened' : ''
            } container mr-0`}
          >
            <div
              className='row mb-1'
              role='row'
            >
              {matchingItem
                ? matchingItem.msg
                : table.rowCells.map((cell: any, inx: number) => populateCell(cell, inx, table.rowType))}
            </div>
            {index < tableLength - 1 && (
              <hr
                className={`mt-2 mb-2
                  ${
                    table.rowType !== InsightRowType.SEPARATED ? 'hr--bold' : ''
                  }`}
              />
            )}
          </div>
        ))
      ) : (
        <div
          key={`TABLE-${Math.random() * 1000000}`}
          className={`collapseCheckList ${
            isOpened ? 'opened' : ''
          } container mr-0`}
        >
          <p className='p-small'>{ErrorMessage.PROFITLOST}</p>
        </div>
      )}
    </div>
  );
};

RuleCheckList.defaultProps = {
  defaultOpen: false,
};

export default RuleCheckList;
