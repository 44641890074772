import * as React from 'react';
import { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import { DateTime } from 'luxon';
import { Brand } from '../../../ts/enums/Colors';
import DataCell from '../../atoms/DataCell';
import {
  CircleMinus,
  CirclePlus,
} from '../../atoms/Icons';
import ImageCell from '../../atoms/ImageCell';
import LinkCell from '../../atoms/LinkCell';
import { ICheckList } from '../../../ts/interfaces/molecules/CheckLists';
import CheckListActions from './CheckListActions';
import ChecklistTableTooltip from '../../../components/checklist/ChecklistTableTooltip';
import { useStore } from '../../../stores/Store';

/**
 * Component that renders the Insight Items for the Bank Reconcilliation
 * section of the Period Close page
 * @param props - the props of the component
 * @returns
 */
const BankReconciliationSixColCheckList = ({
  title,
  tables,
  checkListIdentifier,
  selectedPeriod,
  period,
  defaultOpen,
  ospKey,
}: ICheckList) => {
  const { localeStore } = useStore();

  /** State to track if component is expanded */
  const [isOpened, setOpened] = useState(defaultOpen);

  /**
   * Function to toggle the state of the component
   */
  function openSection() {
    if (isOpened === true) setOpened(false);
    else setOpened(true);
  }

  /**
   * Function to return the appropriate class name for the cell
   * based on its cellType
   * @param cellType
   * @returns
   */
  function cellStyle(cellType) {
    switch (cellType) {
      case 'header':
        return 'FieldLabel';
      case 'subheader':
        return 'FieldLabel';
      case 'separated':
        return 'p-small';
      default:
        return '';
    }
  }

  /**
   * Function to return the appropriate spacing class name for the component
   * based on its index and open state
   * @param ix - the index of the component
   * @returns
   */
  function collapseHandler(ix) {
    switch (ix) {
      case 0:
        return isOpened ? 'mt-4' : 'mt-0';
      default:
        return isOpened ? 'mt-2' : 'mt-0';
    }
  }

  /**
   * Function to return the appropriate cell component based on the cellType
   * @param cell - The data to be displayed in the cell
   * @param index - The index of the component
   * @param rowType - The type of row the cell is in
   * @returns
   */
  function populateCell(cell, index, rowType, fullWidth = false) {
    if (cell.cellType === 'link') {
      return (
        <LinkCell
          key={`BANKREC_LINKCELL-${cell.cellType}-${index * Math.random() * 1000000}`}
          data={cell.data}
          link={cell.link}
          index={index}
          size='col-2'
          alignment={index === 0 ? 'text-left' : 'text-right'}
          cellStyle={cellStyle(rowType)}
        />
      );
    }
    if (cell.cellType === 'image') {
      return (
        <ImageCell
          key={`BANKREC_IMAGECELL-${cell.cellType}-${index * Math.random() * 1000000}`}
          data={cell.data}
          link={cell.link}
          index={index}
          size='col-2'
          cellStyle={cellStyle(rowType)}
        />
      );
    }
    return (
      <DataCell
        key={`BANKREC_DATACELL-${cell.cellType}-${index * Math.random() * 1000000
        }`}
        data={cell.data}
        index={index}
        size={fullWidth ? 'col-12' : 'col-2'}
        alignment={index === 0 ? 'text-left' : 'text-right'}
        cellStyle={cellStyle(rowType)}
        tooltip={cell?.tooltip && (<ChecklistTableTooltip tooltipData={cell?.tooltip} />)}
      />
    );
  }

  const periodEnd = period?.periodEnd ? DateTime.fromISO(period.periodEnd).toFormat('dd/LL/y') : 'period end';
  const hasBulletPoints = ['unreconciledTransactions', 'unreconciledBankLines'].includes(checkListIdentifier?.itemId);
  /**
   * Component return
   */
  return (
    <div className='dataHealthChecklistContainer'>
      <div className='dataHealthChecklistHeader'>
        { /* eslint-disable-next-line max-len  */}
        { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={openSection}
        >
          {isOpened ? (
            <CircleMinus color={Brand.blue} />
          ) : (
            <CirclePlus color={Brand.blue} />
          )}
          <span className='TextCTA' style={{ marginLeft: 8 }}>
            {title}
          </span>
        </div>
        <CheckListActions checkListIdentifier={checkListIdentifier} selectedPeriod={selectedPeriod} title={title} />
      </div>
      {(tables && isOpened && hasBulletPoints) && (
        <div className='checklistBullets'>
          <Markdown>{localeStore.translation(`period-close.bullet-points.${checkListIdentifier?.itemId}.${ospKey}`, { periodEnd, baseUrl: process.env.REACT_APP_QB_URL })}</Markdown>
        </div>
      )}
      {tables
        && tables.tableRows.map((table, index) => (
          <div
            key={`TABLE-${index * Math.random() * 1000000}`}
            className={`collapseCheckList ${isOpened ? 'opened' : ''} container ${collapseHandler(index)} mr-0`}
          >
            {index > 0 ? <hr className='mt-2 mb-2' /> : null}
            <div className='row mb-1'>
              {table.rowCells.map((cell, inx, arr) => populateCell(cell, inx, table.rowType, arr.length === 1))}
            </div>
          </div>
        ))}
    </div>
  );
};

BankReconciliationSixColCheckList.defaultProps = {
  defaultOpen: false,
};

/** Export component */
export default BankReconciliationSixColCheckList;
