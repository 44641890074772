import React, { useState } from 'react';
import { Alert, Tooltip } from 'antd';
import { Brand, State } from '../../../ts/enums/Colors';
import DataCell from '../../atoms/DataCell';
import {
  CircleMinus,
  CirclePlus,
  ExclamationTriangle,
  InfoRing,
} from '../../atoms/Icons';
import LinkCell from '../../atoms/LinkCell';
import { ICheckList } from '../../../ts/interfaces/molecules/CheckLists';
import { CircleFinal } from '../../atoms/Icons/CircleFinal';
import { CircleDraft } from '../../atoms/Icons/CircleDraft';
import TaxStatus from '../../../components/tooltipInners/TaxStatus';
import CheckListActions from './CheckListActions';

/**
 * Component that renders the Insight Items for the taxes
 * section of the Period Close page
 * @param props - the props of the component
 * @returns
 */
const TaxesCheckList = ({
  tables,
  title,
  countryCode,
  checkListIdentifier,
  selectedPeriod,
  defaultOpen,
}: ICheckList) => {
  const [isOpened, setOpened] = useState<boolean>(defaultOpen);
  const isAU: boolean = countryCode === 'AU';

  const hasEstimate: boolean = tables?.tableRows?.some((element) => element.rowCells.some((cell) => cell.rawData === 'estimate'));
  const isShowAlert: boolean = isAU && isOpened && (!tables || hasEstimate);

  const alertTitle = (
    <h6 className='alertTitle'>
      Data limitation for Australian BAS / GST Returns
    </h6>
  );

  const alertContent = (
    <>
      <p className='alertContent'>
        Due to recent changes that Xero made,{' '}
        we are currently unable to get some of the finalised BAS from Xero.
      </p>
      <p className='alertContent'>
        What this means:
      </p>
      <ul className='alertContent'>
        <li>
          BAS / GST Return amount shown may be an estimate based only on bank transaction data.
        </li>
        <li>
          Accuracy of this amount may be affected by unreconciled items, late claims, and manual
          {' '}adjustments.
        </li>
        <li>
          Where the estimate is used, the BAS / GST Return status will be shown as "i" indicating
          {' '}Aider's estimate rather than the finalised amount.
        </li>
      </ul>
    </>
  );

  /**
   * Function to toggle the state of the component
   */
  function openSection() {
    if (isOpened) {
      setOpened(false);
    } else {
      setOpened(true);
    }
  }

  /**
   * Function to return the appropriate class name for the cell
   * based on its cellType
   * @param cellType
   * @returns
   */
  function cellStyle(cellType) {
    switch (cellType) {
      case 'header':
        return 'FieldLabel';
      case 'subheader':
        return 'FieldLabel';
      case 'separated':
        return 'p-small';
      default:
        return '';
    }
  }

  /**
   * Function to return the appropriate spacing class name for the component
   * based on its index and open state
   * @param ix - the index of the component
   * @returns
   */
  function collapseHandler(ix) {
    switch (ix) {
      case 0:
        return isOpened ? 'mt-4' : 'mt-0';
      default:
        return isOpened ? 'mt-2' : 'mt-0';
    }
  }

  /**
   * @description Function that determines what kind of icon we want to display
   *              for the GST report status, or if not report status some other image.
   * @param data
   * @returns
   */
  const getGstReportStatusImage = (data) => {
    const reportStatus = data?.rawData ?? '';
    const ToolTip = <TaxStatus status={reportStatus} countryCode={countryCode} />;
    switch (reportStatus) {
      case 'draft':
        return <Tooltip title={ToolTip} placement='bottom'><span><CircleDraft /></span></Tooltip>;
      case 'final':
        return <Tooltip title={ToolTip} placement='bottom'><span><CircleFinal /></span></Tooltip>;
      case 'estimate':
        return <Tooltip title={ToolTip} placement='bottom'><InfoRing /></Tooltip>;
      default:
        return <img src={data.link} alt={data.data} title={data.data} />;
    }
  };

  /**
   * Function to return the appropriate cell component based on the cellType
   * @param cell - The data to be displayed in the cell
   * @param index - The index of the component
   * @param rowType - The type of row the cell is in
   * @returns
   */
  function populateCell(cell, index, rowType) {
    if (cell.cellType === 'link') {
      return (
        <LinkCell
          key={`TAXES_LINKCELL-${cell.cellType}-${index * Math.random() * 1000000}`}
          data={cell.data}
          link={cell.link}
          index={index}
          size={[0, 1, 2].includes(index) ? 'col-3' : 'col-2'}
          alignment={[0, 3].includes(index) ? 'text-left' : 'text-right'}
          cellStyle={cellStyle(rowType)}
        />
      );
    } if (cell.cellType === 'image') {
      return (
        <div
          key={index}
          className={`mt-2 ${cellStyle(rowType)} text-left ${[0, 1, 2].includes(index) ? 'col-3' : 'col-2'}`}
          role='cell'
        >
          {getGstReportStatusImage(cell)}
        </div>
      );
    }
    return (
      <DataCell
        key={`TAXES_DATACELL-${cell.cellType}-${index * Math.random() * 1000000}`}
        data={cell.data}
        prefix={cell.prefix}
        postfix={cell.postfix}
        index={index}
        size={[0, 1, 2].includes(index) ? 'col-3' : 'col-2'}
        alignment={[0, 3].includes(index) ? 'text-left' : 'text-right'}
        cellStyle={cellStyle(rowType)}
      />
    );
  }

  return (
    <div className='dataHealthChecklistContainer'>
      <div className='dataHealthChecklistHeader'>
        {/* eslint-disable-next-line max-len  */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={openSection}
          data-testid={isOpened ? 'circle-minus-icon' : 'circle-plus-icon'}
        >
          {isOpened ? (
            <CircleMinus color={Brand.blue} />
          ) : (
            <CirclePlus color={Brand.blue} />
          )}
          <span
            className='TextCTA'
            style={{ marginLeft: 8 }}
          >
            {title}
          </span>
        </div>

        <CheckListActions
          checkListIdentifier={checkListIdentifier}
          selectedPeriod={selectedPeriod}
          title={title}
        />
      </div>
      {isShowAlert && (
        <Alert
          className='warningAlert'
          message={alertTitle}
          showIcon
          icon={(
            <ExclamationTriangle
              fill={State.warning}
              strokeWidth={0}
            />
          )}
          description={alertContent}
          type='warning'
        />
      )}
      {tables
        && tables.tableRows.map((table, index) => (
          <div
            key={`taxesChecklistTable${index * Math.random() * 999999}`}
            className={`collapseCheckList ${isOpened ? 'opened' : ''
            } container ${collapseHandler(index)} mr-0`}
          >
            {index > 0 ? <hr className='mt-2 mb-2' /> : null}
            <div
              className='row mb-1'
              role='row'
            >
              {table.rowCells.map((cell, inx) => populateCell(cell, inx, table.rowType))}
            </div>
          </div>
        ))}
    </div>
  );
};

TaxesCheckList.defaultProps = {
  defaultOpen: false,
};

export default TaxesCheckList;
