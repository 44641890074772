import React, { useState } from 'react';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import Format, { ValueTypes } from '@aider/aider-formatting-library';
import { AccountEnums, CustomRuleEnums } from '@aider/constants-library';
import _ from 'lodash';
import RuleCheckListOptions from '../../components-v2/molecules/CheckList/RulesCheckListOptions';
import { ICheckListIdentifier } from '../../ts/interfaces/molecules/CheckLists';
import { useStore } from '../../stores/Store';

export interface PracticeRule {
  id: string,
  title: string,
  section: string,
  order: number,
  rule: {
    show: string,
    criteria: string,
    value: number,
    instruction?: string,
    from: string[],
  },
  createdAt?: any,
  updatedAt?: any,
  createdBy: string,
  status: string,
}

interface IChecklistTemplate {
  rule: PracticeRule
}

/**
 * Component that renders the Insight Items for the Bank Reconcilliation
 * section of the Period Close page
 * @param props - the props of the component
 * @returns
 */
const CheckListTemplate = ({
  rule
}: IChecklistTemplate) => {
  const { practiceStore, checklistStore } = useStore();
  const format = new Format();
  format.country = practiceStore.practice?.countryCode || 'NZ';
  /** State to track if component is expanded */
  const [isOpened, setOpened] = useState<boolean>(false);

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setOpened(!isOpened);
  };

  const sanitiseAccountName = (accountKey: string) => {
    if (accountKey.includes('KEYWORDS:')) {
      const keywords = accountKey.replace('KEYWORDS:', '').split(',');
      if (keywords.length === 1) {
        return `accounts with keyword: ${keywords[0]}`;
      } if (keywords.length === 2) {
        return `accounts with keywords: ${keywords[0]} and ${keywords[1]}`;
      } if (keywords.length >= 3) {
        return `accounts with keywords: ${keywords.slice(0, -1).join(', ')}, and ${keywords[keywords.length - 1]}`;
      }
      return '';
    }

    const accountTypeSplit = accountKey.split('.');

    if (accountTypeSplit.length === 1 && accountTypeSplit[0] === 'allAccounts') {
      return CustomRuleEnums.RuleLibrary[rule.section];
    }
    const accountType = accountTypeSplit[accountTypeSplit.length - 1];
    const accountName = AccountEnums.AccountMapping[accountType] || AccountEnums.AccountMapping[accountType] || _.startCase(accountType);

    return accountName?.replace(' accounts', '');
  };

  const ruleInformation = () => {
    if (!isOpened) return <></>;

    return (
      <div>
        {rule.rule.show === CustomRuleEnums.RuleShow.manualChecks ? (
          rule.rule.instruction || <em>No instruction provided</em>
        ) : (
          <>
            Show <b>{format.formatValue({ value: CustomRuleEnums.RuleShow[rule.rule.show], format: ValueTypes.textUpperFirstLowerRest })}</b>{' '}
            with alert criteria{' '}
            <b>
              {CustomRuleEnums.RuleLibrary[rule.rule?.criteria]}{' '}
            </b>
            {(rule.rule?.value || rule.rule?.value === 0) && (
              <>
                {CustomRuleEnums.CriteriaValues[rule.rule?.criteria] === CustomRuleEnums.RuleValues.percentage && (' of ')}
                <b>
                  {format.formatValue({ value: rule.rule?.value, format: ValueTypes[CustomRuleEnums.CriteriaValues[rule.rule?.criteria] as keyof ValueTypes] || ValueTypes.text })}
                </b>
              </>
            )}{CustomRuleEnums.CriteriaValues[rule.rule?.criteria] === CustomRuleEnums.RuleValues.percentage && (' or more ')}{' '}
            {rule.rule?.from && (
              <>
                from{' '}
                <b>
                  {rule.rule?.from.map((from, inx, arr) => (
                    <span key={from}>
                      {inx > 0 && inx !== arr.length - 1 && (',')}{inx > 0 && inx === arr.length - 1 && (' and')} {sanitiseAccountName(from)}
                    </span>
                  ))}
                </b> {rule.rule?.from.some((item) => item.includes('KEYWORDS:')) ? '' : 'accounts'}
              </>
            )}
          </>
        )}
      </div>
    );
  };

  const checklistIdentifier: ICheckListIdentifier = {
    checklistId: checklistStore.selectedChecklistId,
    sectionId: rule.section,
    itemId: rule.id,
    type: '',
  };

  return (
    <div className='practice-checklist'>
      <div
        className='practice-checklist__summary'
        onClick={handleOpen}
      >
        <h5 className='practice-checklist__title'>
          {isOpened ? (<MinusCircleOutlined />) : (<PlusCircleOutlined />)}
          {rule.title}
        </h5>
        <div className='practice-checklist__actions'>
          <RuleCheckListOptions checkListIdentifier={checklistIdentifier} isPractice />
        </div>
      </div>
      {isOpened && (
        <div className='practice-checklist__detail'>
          {ruleInformation()}
        </div>
      )}
    </div>
  );
};

/** Export component */
export default CheckListTemplate;
