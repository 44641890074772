import { TextRun, ExternalHyperlink } from 'docx';
import { BankAccount, RawBankAccount } from '../models/interfaces/stores';

export function convertBankAccounts(bankAccounts: RawBankAccount[]): BankAccount[] {
  return bankAccounts.map((account) => ({
    accountId: account.account_id,
    accountCode: account.account_code,
    accountType: account.account_type,
    name: account.account_name,
    id: account.account_id,
    number: account.account_code,
    businessId: account.business_id,
    createdAt: account.created_at,
    currencyCode: account.currency_code,
    entityId: account.entity_id,
    systemAccount: account.system_account,
    updatedAt: account.updated_at,
    accountName: account.account_name,
  }));
}

/**
  * Interlaces style ranges to correctly handle overlapping styles
  * Used to convert Draft.js inlineStyleRanges to styled substring groups
  * @param styleRanges - Array of style ranges
  * returns
  */
export function interlaceStyleRanges(styleRanges: {
  offset: number;
  length: number;
  style: string
}[]) : {
  offset: number;
  length: number;
  style: string[]
}[] {
  styleRanges.sort((a, b) => a.offset - b.offset);
  const result = [];

  styleRanges.forEach(({ offset, length, style }) => {
    const rangeEnd = offset + length;
    let found = false;

    for (let i = 0; i < result.length; i++) {
      const item = result[i];
      const itemEnd = item.offset + item.length;
      const itemOffset = item.offset;

      if (offset >= item.offset && offset <= itemEnd) {
        if (offset > item.offset) {
          item.length = offset - itemOffset;

          if (rangeEnd <= itemEnd) {
            result.push({
              offset,
              length,
              style: [...item.style, style],
            });
          }

          if (rangeEnd < itemEnd) {
            result.push({
              offset: rangeEnd,
              length: itemEnd - rangeEnd,
              style: item.style,
            });
          }
        }

        if (offset === item.offset && length === item.length) {
          item.length = rangeEnd - item.offset;
          item.style.push(style);
        }

        found = true;
        break;
      }
    }

    if (!found) {
      result.push({
        offset,
        length,
        style: [style],
      });
    }
  });

  return result;
}

/**
 * Converts Draft.js text block to styled substring groups for
 * inserting into DocX templates
 * @param text - The text to convert
 * @param inlineStyleRanges - Array of style ranges
 * returns
 */
export function convertToStyledSubstrings({ text, inlineStyleRanges, entityRanges }, entityMap) {
  const interlacedRanges = interlaceStyleRanges(inlineStyleRanges);
  const pairings = [];
  interlacedRanges.forEach((range, rangeIndex) => {
    entityRanges.forEach((entityRange, entityIndex) => {
      if ((range.offset <= entityRange.offset && range.offset + range.length >= entityRange.offset)
          || (range.offset + range.length >= entityRange.offset && range.offset + range.length <= entityRange.offset + entityRange.length)
          || (range.offset >= entityRange.offset && range.offset <= entityRange.offset + entityRange.length)
      ) {
        pairings.push([rangeIndex, entityIndex]);
      }
    });
    if (pairings.length > 1) {
      return pairings;
    }
    return false;
  });

  const result = [];
  let currentIndex = 0;

  let textSlice;
  let segmentEntities = [];

  interlacedRanges.forEach(({ offset, length, style }, index) => {
    const entities = pairings.filter((pair) => pair[0] === index).map((pair) => entityRanges[pair[1]]);

    if (currentIndex < offset) {
      textSlice = text.slice(currentIndex, offset);
      segmentEntities = entities.filter((entity) => (
        (entity.offset >= currentIndex && entity.offset < offset)
        || (entity.offset + entity.length >= currentIndex && entity.offset + entity.length <= offset)
        || (entity.offset >= currentIndex && entity.offset + entity.length >= offset)
      ));
      if (segmentEntities.length > 0) {
        segmentEntities.forEach((entity) => {
          const entityData = entityMap?.[entity.key];
          const start = entity.offset < currentIndex ? 0 : entity.offset - currentIndex;
          const end = start + entity.length > textSlice.length ? textSlice.length : start + entity.length;
          if (start > 0) {
            result.push({
              text: textSlice.slice(0, start),
              style: null,
            });
          }
          result.push({
            text: textSlice.slice(start, end),
            entityData,
          });
          if (end < textSlice.length) {
            result.push({
              text: textSlice.slice(end),
              style: null,
            });
          }
        });
      } else {
        result.push({
          text: textSlice,
          style: null,
        });
      }
    }

    textSlice = text.slice(offset, offset + length);

    segmentEntities = entities.filter((entity) => (
      (entity.offset >= offset && entity.offset <= offset + length)
      || (entity.offset + entity.length >= offset && entity.offset + entity.length <= offset + length)
      || (entity.offset >= offset && entity.offset + entity.length >= offset + length)
    ));
    if (segmentEntities.length > 0) {
      segmentEntities.forEach((entity) => {
        const entityData = entityMap?.[entity.key];
        const start = entity.offset < offset ? 0 : entity.offset - offset;
        const end = (start - (offset - entity.offset) + entity.length) > textSlice.length ? textSlice.length : start - (offset - entity.offset) + entity.length;
        if (start > 0) {
          result.push({
            text: textSlice.slice(0, start),
            style,
          });
        }
        result.push({
          text: textSlice.slice(start, end),
          entityData,
          style,
        });
        if (end < textSlice.length) {
          result.push({
            text: textSlice.slice(end),
            style,
          });
        }
      });
    } else {
      result.push({
        text: textSlice,
        style,
      });
    }

    currentIndex = offset + length;
  });

  if (currentIndex < text.length) {
    textSlice = text.slice(currentIndex);
    segmentEntities = entityRanges.filter((entity) => (
      entity.offset >= currentIndex
      || entity.offset + entity.length >= currentIndex
    ));

    if (segmentEntities.length > 0) {
      segmentEntities.forEach((entity) => {
        const entityData = entityMap?.[entity.key];
        const start = entity.offset < currentIndex ? 0 : entity.offset - currentIndex;
        const end = (start - (currentIndex - entity.offset) + entity.length) > textSlice.length ? textSlice.length : start - (currentIndex - entity.offset) + entity.length;
        if (start > 0) {
          result.push({
            text: textSlice.slice(0, start),
            style: null,
          });
        }
        result.push({
          text: textSlice.slice(start, end),
          entityData,
          style: null,
        });
        if (end < textSlice.length) {
          result.push({
            text: textSlice.slice(end),
            style: null,
          });
        }
      });
    } else {
      result.push({
        text: textSlice,
        style: null,
      });
    }
  }
  return result;
}

export function generateStyledChildren(block: any, entityMap: any = {}) {
  return (convertToStyledSubstrings(block, entityMap).flatMap((styledSubstring) => {
    const textRun = new TextRun({
      text: styledSubstring.text,
      bold: styledSubstring.style?.includes('BOLD'),
      italics: styledSubstring.style?.includes('ITALIC'),
      style: styledSubstring?.entityData ? 'Hyperlink' : null,
    });
    if (styledSubstring.entityData) {
      return new ExternalHyperlink({
        children: [textRun],
        link: styledSubstring.entityData?.data?.url,
      });
    }

    return textRun;
  }));
}
