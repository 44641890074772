import Format, { enums } from '@aider/aider-formatting-library';
import { DateTime } from 'luxon';
import { DashboardEnums } from '@aider/constants-library';

const format = new Format();
const { FormatTypes, ValueTypes } = enums;

const currentDate = DateTime.now().endOf('month');
const lastDate = DateTime.now().minus({ months: 1 }).endOf('month');
const periodFormat = { month: FormatTypes.short, year: FormatTypes.short };
const dayFormat = { day: FormatTypes.short, month: FormatTypes.short };

const currentPeriod = format.formatValue({
  format: ValueTypes.formatDate,
  value: currentDate,
  dateFormat: periodFormat
});
const lastPeriod = format.formatValue({
  format: ValueTypes.formatDate,
  value: lastDate,
  dateFormat: periodFormat
});
const currentPeriodDay = format.formatValue({
  format: ValueTypes.formatDate,
  value: currentDate,
  dateFormat: dayFormat
});
const lastPeriodDay = format.formatValue({
  format: ValueTypes.formatDate,
  value: lastDate,
  dateFormat: dayFormat
});

export const DashboardColumns = {
  revenue: {
    insight: 'profitability',
    type: DashboardEnums.ColumnTypes.trend,
  },
  netProfit: {
    insight: 'profitability',
    type: DashboardEnums.ColumnTypes.trend,
  },
  incometax: {
    insight: 'compliance',
    type: DashboardEnums.ColumnTypes.trend,
    ews: true,
  },
  unreconciled: {
    insight: 'health',
    type: DashboardEnums.ColumnTypes.alert,
  },
  cashflow: {
    insight: 'cashflow',
    type: DashboardEnums.ColumnTypes.trend,
    ews: true,
    tooltipOptions: {
      currentPeriod,
      lastPeriod,
      currentPeriodDay,
      lastPeriodDay
    },
  },
  gstestimate: {
    insight: 'compliance',
    type: DashboardEnums.ColumnTypes.trend,
    ews: true,
  },
  gstcompletion: {
    insight: 'compliance',
    type: DashboardEnums.ColumnTypes.gst,
  }
};

export const sectionResyncTooltips = {
  bankReconciliation: {
    title: 'Resync reconciliation data for this period.',
    content: 'Quicker than the standard Resync. Use when you are working only on the reconciliation tasks.',
  },
};

export const WYSIWIG_TOOLBAR_OPTIONS = {
  text: ['blockType', 'inline', 'textAlign', 'list', 'link', 'history'],
  header: ['textAlign', 'history'],
};
