import React from 'react';
import { Form, Input } from 'antd';
import { observer } from 'mobx-react';
import { PracticeTypes } from '@aider/constants-library';
import Markdown from 'markdown-to-jsx';
import { useStore } from '../../../stores/Store';
import { trackMixpanelEvent } from '../../../lib/mixpanel';

const GeneralComponent = ({
  className,
  block
}: {
  className: string,
    block: PracticeTypes.ReportBlock
}) => {
  const rootStore = useStore();
  const { reportTemplateStore } = rootStore;
  const isEditing = reportTemplateStore.editBlock === block.id;
  return (block.content || block.content === '') && (
    <Form.Item
      className={`${className} ${className}--${isEditing ? 'editing' : 'read-only'}`}
      name={block.id}
      label={null}
      initialValue={block.content}
    >
      {isEditing ? (
        <Input.TextArea
          onBlur={() => {
            trackMixpanelEvent({ description: 'Report Editor - Save Block', properties: { editBy: 'blur' }, rootStore });
            reportTemplateStore.editBlock = null;
          }}
          autoFocus
        />
      ) : (
        <Markdown
          onClick={() => {
            trackMixpanelEvent({ description: 'Report Editor - Edit Block', properties: { editBy: 'click' }, rootStore });
            reportTemplateStore.editBlock = block.id;
          }}
        >
          {block.content}
        </Markdown>
      )}
    </Form.Item>
  );
};

export default observer(GeneralComponent);
