import React from 'react';
import { observer } from 'mobx-react';
import { CustomRuleEnums } from '@aider/constants-library';
import { useStore } from '../../../stores/Store';
import AiderAlert from '../../../components/AiderAlert';

interface IPendingRuleBannerProps {
  businessId: string;
  sectionId: string;
  checklistId: string;
}

const PendingRuleBanner = ({ businessId, sectionId, checklistId }: IPendingRuleBannerProps) => {
  const { practiceStore, rulesStore } = useStore();
  const hasRule = (
    CustomRuleEnums.RuleCategories[sectionId]
    && rulesStore.pendingRules?.get(businessId)?.get(checklistId)?.get(sectionId)?.size > 0
  );

  return hasRule
    && (
      <aside className={businessId !== practiceStore.id ? 'main dataHealthChecklistContainer mb-3' : 'practice-checklist__banner'}>
        <AiderAlert type='info' title='Updating the checklist for this section... Please wait.'>
          <p>
            New or edited rules will appear automatically when this is done,
            {' '}and deleted rules removed.
          </p>
        </AiderAlert>
      </aside>
    );
};

export default observer(PendingRuleBanner);
