import * as React from 'react';
import { IDataCell } from '../../ts/interfaces/atoms/DataCell';

const getSpacer = (newline:boolean) => (newline ? <br /> : ' ');

const DataCell = ({
  data,
  prefix,
  postfix,
  index,
  alignment,
  size,
  cellStyle,
  tooltip,
}: IDataCell) => (
  <div
    key={index}
    className={`mt-2 ${cellStyle} ${alignment} ${size}`}
    role='cell'
    style={{ position: 'relative' }}
  >
    {prefix && (
      <>
        <span style={{ fontWeight: 700 }}>{prefix.value}</span>
        {getSpacer(prefix.newline)}
      </>
    )}
    {data}

    {tooltip && tooltip}

    {postfix && (
      <>
        {getSpacer(postfix.newline)}
        <span className={`checklist-postfix checklist-postfix__${postfix?.type ? postfix.type : 'standard'}`}>
          {postfix.value}
        </span>
      </>
    )}
  </div>
);

DataCell.defaultProps = {
  prefix: undefined,
  postfix: undefined,
};

export default DataCell;
