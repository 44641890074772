import React, { useEffect } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import Format from '@aider/aider-formatting-library';
import { observer } from 'mobx-react';
import { ClientManagementEnums, CustomRuleEnums } from '@aider/constants-library';
import { ValueDefault, ValuePrefix, ValueSuffix } from '../../../models/enums/Rules';
import { IRuleSelectOptions } from '../../../ts/interfaces/molecules/DataComplianceRules';
import { useStore } from '../../../stores/Store';
import { RuleCriteria } from '../../../ts/constants';
import AccountsTreeSelect from '../../form/AccountsTreeSelect';
import { usePrevious } from '../../../entities/utils';

const rulePropertyMapping = {
  [CustomRuleEnums.RuleShow.accounts]: ['criteria', 'value', 'from'],
  [CustomRuleEnums.RuleShow.transactions]: ['criteria', 'value', 'from'],
  [CustomRuleEnums.RuleShow.manualChecks]: ['instruction'],
};

const criteriaPropertyOverrideMapping = {
  [CustomRuleEnums.AccountRuleCriteria.percentIncrease]: ['criteria', 'value', 'from'],
  [CustomRuleEnums.AccountRuleCriteria.percentDecrease]: ['criteria', 'value', 'from'],
  [CustomRuleEnums.AccountRuleCriteria.percentChange]: ['criteria', 'value', 'from'],
  [CustomRuleEnums.AccountRuleCriteria.above]: ['criteria', 'value', 'from'],
  [CustomRuleEnums.AccountRuleCriteria.aboveOrEqual]: ['criteria', 'value', 'from'],
  [CustomRuleEnums.AccountRuleCriteria.belowOrEqual]: ['criteria', 'value', 'from'],
  [CustomRuleEnums.AccountRuleCriteria.below]: ['criteria', 'value', 'from'],
};

const keywordsPrefix: string = 'KEYWORDS:';

interface IRuleModalContentProps {
  businessId: string;
  newRule: boolean;
  form: any;
  rule?: any;
  sectionId: string;
  allowKeywords?: boolean;
}

const RuleModalContent = ({
  businessId,
  newRule,
  form,
  rule,
  sectionId,
  allowKeywords,
}: IRuleModalContentProps) => {
  const rootStore = useStore();
  const format = new Format();
  const isPractice = businessId === rootStore.practiceStore.id;
  const [formHasValue, setFormHasValue] = React.useState<boolean>(false);
  const [valuePrefix, setValuePrefix] = React.useState<string | any>(' ');
  const [valueSuffix, setValueSuffix] = React.useState<string | any>(' ');
  const [fromSelectMethod, setFromSelectMethod] = React.useState<'multiple' | 'single'>(null);
  const [formFields, setFormFields] = React.useState<string[]>(rulePropertyMapping[CustomRuleEnums.RuleShow.transactions]);
  const showValue = Form.useWatch('show', form);
  const criteriaValue = Form.useWatch('criteria', form);
  const prevFromMethod = usePrevious(fromSelectMethod);
  const keywordsValue = Form.useWatch('keywordFrom', form);
  const valueValue = Form.useWatch('value', form);
  const prevValue = usePrevious(valueValue);
  const isMultiSelect = fromSelectMethod === 'multiple';
  let fromValue = [];

  if (isPractice) {
    fromValue = rootStore.practiceStore.connectedOSPs.map((osp) => Form.useWatch(`from-${osp}`, form));
    console.log('fromValue', fromValue);
  } else {
    fromValue = [Form.useWatch('from', form)];
  }

  const rulesOnlyShow = () => (
    [CustomRuleEnums.RuleCategories.profitAndLoss as string, CustomRuleEnums.RuleCategories.balanceSheet as string].includes(sectionId)
  );

  const getInitialKeywordsValue = () => {
    if (!rule?.rule?.from[0]?.includes(keywordsPrefix)) return [];
    const keywordsString = rule?.rule.from[0];
    if (keywordsString.startsWith(keywordsPrefix)) {
      return keywordsString.slice(keywordsPrefix.length).split(',');
    }
    return [];
  };

  // Component default settings
  const gutter = 0;
  const fieldSize = 'large';
  const defaultShow = rulesOnlyShow() ? CustomRuleEnums.RuleShow.transactions : CustomRuleEnums.RuleShow.manualChecks;
  /**
   * Map through the CustomRuleEnums.RuleShow enum to generate an array of options for the show field
   * @returns {IRuleSelectOptions[]} - Array of options for the show field
   */
  const showOptions = (): IRuleSelectOptions[] => (
    Object.keys(CustomRuleEnums.RuleShow).filter((show) => (
      (show !== CustomRuleEnums.RuleShow.manualChecks) ? rulesOnlyShow() : true
    )).map((key) => ({
      value: CustomRuleEnums.RuleShow[key], label: CustomRuleEnums.RuleLibrary[key]
    }))
  );

  /**
   * Generate an array of options for the criteria field based on the selected show field
   * @param {object} criteriaOptionsSource - Object containing the criteria options for
   *                                         the selected show field
   * @returns {IRuleSelectOptions[]} - Array of options for the criteria field
   */
  const generateCriteriaOptions = (criteriaOptionsSource): IRuleSelectOptions[] => (
    Object.keys(criteriaOptionsSource).filter((key) => {
      if (!isPractice) return true;
      return !Object.keys(CustomRuleEnums.PracticeRuleExceptions).includes(key);
    }).map((key) => ({
      value: key, label: CustomRuleEnums.RuleLibrary[key]
    }))
  );

  /**
   * Obtain the appropriate prefix for the value field based on the selected criteria
   * @returns string | Icon - The prefix for the value field
   */
  const getValuePrefix = (criteria: string): string => {
    let prefix = ValuePrefix[CustomRuleEnums.RuleValues[CustomRuleEnums.CriteriaValues[criteria]]];
    if (CustomRuleEnums.CriteriaValues[criteria] === CustomRuleEnums.RuleValues.currency) {
      if (businessId === rootStore.practiceStore.id) {
        format.country = rootStore.practiceStore.countryCode;
        prefix = format.currencySymbol;
      } else {
        prefix = rootStore.businessesStore.businesses.get(businessId)?.currencySymbol;
      }
    }
    return prefix;
  };

  /**
   * Obtain the appropriate suffix for the value field based on the selected criteria
   * @returns string | Icon - The suffix for the value field
   */
  const getValueSuffix = (criteria: string): string => {
    const suffix = ValueSuffix[CustomRuleEnums.RuleValues[CustomRuleEnums.CriteriaValues[criteria]]];
    return suffix;
  };

  // Define state variables for handling the dynamic changing of the form
  const [criteriaOptions, setCriteriaOptions] = React.useState<IRuleSelectOptions[]>(
    generateCriteriaOptions(
      RuleCriteria[rule ? rule.rule.show : defaultShow]
    )
  );

  // Methods that mutate state

  // Reset criteria field seleted value when criteria options change
  useEffect(() => {
    const indexOfCriteria: number = rule ? criteriaOptions.findIndex(
      (element) => element.value === rule.rule.criteria
    ) : 0;
    form.setFieldValue('criteria', criteriaOptions[indexOfCriteria >= 0 ? indexOfCriteria : 0].value);
  }, [criteriaOptions]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Update the value field based on the selected criteria field
   * @param criteria - The value of the selected criteria field
   */
  const updateValue = (criteria: string) => {
    if (Object.keys(criteriaPropertyOverrideMapping).includes(criteria)) {
      setFormFields(criteriaPropertyOverrideMapping[criteria]);
    }
    const newFieldValues: { [key: string]: any } = {
      value: ValueDefault[CustomRuleEnums.RuleValues[CustomRuleEnums.CriteriaValues[criteria]]],
    };

    setFromSelectMethod(CustomRuleEnums.CriteriaSelectionMethod[criteria]);
    form.setFieldsValue(newFieldValues);
  };

  /**
   * Update the criteria options based on the selected show field
   * @param value - The value of the selected show field
   */
  useEffect(() => {
    if (showValue) {
      setFormFields(rulePropertyMapping[showValue]);
      setCriteriaOptions(generateCriteriaOptions(RuleCriteria[showValue]));
      updateValue(Object.values(RuleCriteria[showValue])[0] as string);
    }
  }, [showValue]);

  /**
   * Update the value field based on the selected criteria field
   * @param criteria - The value of the selected criteria field
   */
  useEffect(() => {
    if (criteriaValue) {
      setFormHasValue(CustomRuleEnums.CriteriaValues[criteriaValue] !== CustomRuleEnums.RuleValues.none);
      setValuePrefix(getValuePrefix(criteriaValue));
      setValueSuffix(getValueSuffix(criteriaValue));
      updateValue(criteriaValue);
    }
  }, [criteriaValue]);

  /**
   * Set the initial value of the value field based on the selected criteria field
   * on initial render once we have all of the required data
   */
  useEffect(() => {
    if (!prevValue && prevValue !== '') {
      let value = ValueDefault[CustomRuleEnums.RuleValues[CustomRuleEnums.CriteriaValues[criteriaValue]]];
      if (rule?.rule?.value || rule?.rule?.value === 0) {
        value = rule.rule.value;
      }
      form.setFieldsValue({ value });
    }
  }, [rule?.rule?.value, criteriaValue, prevValue]);

  /**
   * Reset the from field if the selection method changes from single select
   * to multi select
   */
  useEffect(() => {
    const getFormObject = (setValue: string | string[]) => {
      const fromObj: any = {};
      if (isPractice) {
        rootStore.practiceStore.connectedOSPs.forEach((osp) => {
          fromObj[`from-${osp}`] = setValue;
        });

        return fromObj;
      }

      fromObj.from = setValue;
      return fromObj;
    };

    if (fromSelectMethod && prevFromMethod && prevFromMethod !== fromSelectMethod) {
      if (fromSelectMethod === 'single') {
        form.setFieldsValue(getFormObject(''));
      } else {
        form.setFieldsValue(getFormObject([]));
      }
    }
  }, [fromSelectMethod, prevFromMethod, prevValue]);

  // Methods that render JSX
  return (newRule || rule?.rule) && (
    <Form
      form={form}
      layout='vertical'
      name='rule-builder'
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: true }}
      autoComplete='off'
      className='rule-form'
      requiredMark={formFields.includes('instruction') ? 'optional' : false}
    >
      <Form.Item
        name='id'
        initialValue={rule?.id}
        hidden
      >
        <Input />
      </Form.Item>

      <Row
        gutter={gutter}
        style={{ marginBottom: '1rem' }}
      >
        <Col span={24}>
          <Form.Item
            name='title'
            initialValue={rule?.title}
            rules={[{ required: true, message: 'Please name this data check' }]}
          >
            <Input
              size={fieldSize}
              placeholder='Name your new data check'
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={gutter}>
        <Col span={5}>
          <Form.Item
            name='show'
            label='Show'
            initialValue={rule ? rule?.rule?.show : defaultShow}
            rules={[
              {
                required: true,
                message: 'Please select what you want to show',
              },
            ]}
          >
            <Select
              size={fieldSize}
              options={showOptions()}
            />
          </Form.Item>
        </Col>

        {formFields.includes('criteria') && (
          <Col span={7}>
            <Form.Item
              name='criteria'
              label='Alert Criteria'
              initialValue={
                rule ? rule?.rule?.criteria : Object.values(criteriaOptions)[0]
              }
              rules={[
                { required: true, message: 'Please select an alert criteria' },
              ]}
            >
              <Select
                size={fieldSize}
                options={criteriaOptions}
              />
            </Form.Item>
          </Col>
        )}

        {formFields.includes('value') && !!criteriaValue && (
          <Col span={formHasValue ? 3 : 0}>
            <Form.Item
              name='value'
              label='Amount'
              rules={[
                { required: formHasValue, message: 'Please enter an amount' },
              ]}
              hidden={!formHasValue}
              className='hidden-label'
            >
              <Input
                prefix={valuePrefix}
                suffix={valueSuffix}
                size={fieldSize}
              />
            </Form.Item>
          </Col>
        )}

        {formFields.includes('from') && !!fromSelectMethod && (
          <Col span={formHasValue ? 9 : 12}>
            {
              isPractice
                ? rootStore.practiceStore.connectedOSPs.map((osp, index) => (
                  <AccountsTreeSelect
                    key={`${osp}-accounts-tree-select`}
                    label={index === 0 ? 'From' : ''}
                    sectionId={sectionId}
                    treeCheckable={isMultiSelect}
                    rule={rule}
                    businessId={businessId}
                    disabled={keywordsValue && keywordsValue.length > 0}
                    placeholder={`Select ${ClientManagementEnums.OSPNames[osp]} account types`}
                    osp={osp}
                    required={!fromValue.flatMap((from) => from).length && !(keywordsValue && keywordsValue.length > 0)}
                  />
                ))
                : (
                  <AccountsTreeSelect
                    label='From'
                    sectionId={sectionId}
                    treeCheckable={isMultiSelect}
                    rule={rule}
                    disabled={keywordsValue && keywordsValue.length > 0}
                    businessId={businessId}
                  />
                )
            }
            <Form.Item
              name='keywordFrom'
              label='OR accounts with keywords...'
              initialValue={getInitialKeywordsValue()}
              rules={[
                {
                  required: allowKeywords && (!fromValue || !fromValue.flatMap((from) => from).length),
                  message: 'Please enter at least one keyword',
                },
              ]}
              hidden={!allowKeywords}
              style={{ marginTop: '10px' }}
            >
              <Select
                size={fieldSize}
                mode='tags'
                disabled={fromValue && !!fromValue.flatMap((from) => from).length}
              />
            </Form.Item>
          </Col>
        )}

        {formFields.includes('instruction') && (
          <Col span={19}>
            <Form.Item
              name='instruction'
              label='Instruction'
              initialValue={rule?.rule?.instruction}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

RuleModalContent.defaultProps = {
  rule: undefined,
  allowKeywords: false,
};

export default observer(RuleModalContent);
