import React from 'react';
import { PracticeTypes } from '@aider/constants-library';
import { observer } from 'mobx-react';
import PageComponent from './EditorComponents/PageComponent';
import GeneralComponent from './EditorComponents/GeneralComponent';
import TextComponent from './EditorComponents/TextComponent';

const EditorRowContent = ({
  block,
  blockInstance
} : {
  block: PracticeTypes.ReportBlock,
  blockInstance: number
}) => {
  const className = 'report-editor__content__row--content';

  if (block.type === 'page') {
    return (
      <PageComponent
        className={className}
        block={block}
        blockInstance={blockInstance}
      />
    );
  }

  if (block.type === 'text' || block.type === 'header') {
    return (
      <TextComponent
        className={className}
        block={block}
      />
    );
  }

  return (
    <GeneralComponent
      className={className}
      block={block}
    />
  );
};

export default observer(EditorRowContent);
