import { PracticeTypes, ReportEnums } from '@aider/constants-library';
import { makeAutoObservable } from 'mobx';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { capitalizeString } from '@aider/aider-formatting-library/dist/lib/formatUtils';
import { Document as DocXDoc, SectionType, Paragraph, HeadingLevel, Packer } from 'docx';
import fileDownload from 'js-file-download';
import { DELETE, GET, POST, PUT } from '../lib/requests';
import type { RootStore } from './Store';
import handleError from '../lib/errorHandler';
import Notification from '../components/Notification';
import { generateStyledChildren } from '../lib/storeUtils';
import { DOCX_CONFIG } from '../models/constants/stores';

export default class ReportTemplateStore {
  rootStore: RootStore;

  performanceReportTemplates: Map<string, PracticeTypes.ReportPage> = new Map();

  selectedPerformanceReportTemplate: string = ReportEnums.ReportPredefinedTemplates.default;

  editedPerformanceReportTemplate: PracticeTypes.ReportPage;

  editBlock: string;

  get reportTemplateSelectionList() {
    const reportTemplateSelectionList = _.sortBy(Array.from(this.performanceReportTemplates.values()), ['templateName'])
      .map((reportTemplate) => ({
        value: reportTemplate.templateId,
        label: reportTemplate.templateName,
      }));
    return reportTemplateSelectionList;
  }

  get sortedPerformanceReportTemplates() {
    return Array.from(this.performanceReportTemplates.values()).sort((a, b) => {
      if (a.templateName < b.templateName) {
        return -1;
      }
      if (a.templateName > b.templateName) {
        return 1;
      }
      return 0;
    });
  }

  get selectedPerformanceReport() {
    const selectedPerformanceReport = this.editedPerformanceReportTemplate || this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate);
    const sortedBlocks = _.sortBy(selectedPerformanceReport?.blocks, ['position']);
    return { ...selectedPerformanceReport, blocks: sortedBlocks };
  }

  get selectedPerformanceReportPageCount() {
    return this.selectedPerformanceReport?.blocks?.filter((block) => block.type === 'page').length || 0;
  }

  get selectedPerformanceReportBlocks() {
    return _.sortBy(this.selectedPerformanceReport.blocks, ['position']) || [];
  }

  get isDefaultTemplateSelected() {
    return this.selectedPerformanceReportTemplate === ReportEnums.ReportPredefinedTemplates.default;
  }

  get isTemplateEdited() {
    return !!this.editedPerformanceReportTemplate
      && !_.isEqual(this.editedPerformanceReportTemplate, this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate));
  }

  /**
   * Prepares the selected report template for editing
   * by creating a deep clone of the selected template and sorting the blocks by position
   * to ensure they are in the correct order
   */
  prepEditReportTemplate() {
    const templateClone = _.cloneDeep(this.performanceReportTemplates.get(this.selectedPerformanceReportTemplate));
    const sortedBlocks = _.sortBy(templateClone.blocks, ['position']);
    this.editedPerformanceReportTemplate = { ...templateClone, blocks: sortedBlocks };
  }

  /**
   * Sets the selected report template block to the edited content
   * @param editedContent - The edited content to set
   * @param blockId - The block id to set the content for
   */
  editReportTemplate(editedContent: PracticeTypes.ReportBlock['content'], blockId: PracticeTypes.ReportBlock['id']) {
    const inx = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    this.editedPerformanceReportTemplate.blocks[inx].content = editedContent;
  }

  /**
   * Gets the block position and index for the block id
   * @param blockId - The block id to find
   * @returns The block index and position
   */
  getBlockPositionAndIndex(blockId: string): { blockIndex: number, blockPosition: PracticeTypes.ReportBlock['position'] } {
    let blockIndex: number = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    let blockPosition: PracticeTypes.ReportBlock['position'];

    if (blockIndex === -1) {
      blockIndex = this.editedPerformanceReportTemplate.blocks.length;
      blockPosition = (this.editedPerformanceReportTemplate.blocks.slice(-1)[0]?.position || -1) + 1;
    } else {
      blockPosition = this.editedPerformanceReportTemplate.blocks[blockIndex].position;
    }

    return { blockIndex, blockPosition };
  }

  /**
   * Gets the index and position for the next page block after the block id
   * @param blockId - The block id to find the next page block after
   * @returns The next page block index and position
   */
  getNextPagePositionAndIndex(blockId: string): { nextPageInx: number, nextPagePosition: PracticeTypes.ReportBlock['position'] } {
    const templateIndex = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    const searchStartIndex = templateIndex === -1 ? 0 : templateIndex + 1;
    const nextPageInx = this.editedPerformanceReportTemplate.blocks.findIndex((block, index) => index >= searchStartIndex && block.type === 'page');
    const nextPagePosition = nextPageInx !== -1 ? this.editedPerformanceReportTemplate.blocks[nextPageInx].position : this.editedPerformanceReportTemplate.blocks.length;
    return { nextPageInx, nextPagePosition };
  }

  /**
    * Adds a page block to the end of the report template
    */
  addPageToEnd() {
    const lastPosition = this.editedPerformanceReportTemplate.blocks.slice(-1)[0]?.position || -1;
    this.addPageToPosition(
      this.editedPerformanceReportTemplate.blocks.length,
      lastPosition + 1
    );
  }

  /**
    * Adds a page block to the target index
    * @param targetInx - The target index to add the page block to
    * @param position - The position to set the page block to
    */
  addPageToPosition(targetInx: number, position: PracticeTypes.ReportBlock['position']) {
    if (targetInx === -1) {
      this.addPageToEnd();
      return;
    }
    this.editedPerformanceReportTemplate.blocks.splice(targetInx, 0, {
      id: uuid(),
      type: 'page',
      position,
      content: null
    });
  }

  /**
    * Increments the position of all blocks from the start index to the end of
    * the blocks array
    */
  incrementBlockPositions(startIndex: number) {
    if (startIndex === -1) {
      return;
    }

    for (let i = startIndex; i < this.editedPerformanceReportTemplate.blocks.length; i += 1) {
      this.editedPerformanceReportTemplate.blocks[i].position += 1;
    }
  }

  /**
    * Iterates through all blocks and reevaluates the block positions
    * to cleanup any gaps in the block positions
    * This is useful when deleting blocks
    */
  reevaluateBlockPositions() {
    const placedBlocks = [];
    this.editedPerformanceReportTemplate.blocks.forEach((block, index) => {
      const updatedBlock = { ...block };
      updatedBlock.position = index;
      placedBlocks.push(updatedBlock);
    });
    this.editedPerformanceReportTemplate.blocks = placedBlocks;
  }

  /**
    * Adds a page block before the target block id
    * @param blockId - The block id to add the page block before
    */
  addPageBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);
    this.addPageToPosition(blockIndex, blockPosition);
  }

  /**
    * Deletes a page block and all blocks after it
    * up to the next page block
    * @param blockId - The block id to delete
    */
  deletePageBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const blockIndex = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    if (blockIndex === -1) {
      return;
    }

    let { nextPageInx } = this.getNextPagePositionAndIndex(blockId);
    if (nextPageInx === -1) {
      // Is last page so delete all blocks after this one
      nextPageInx = this.editedPerformanceReportTemplate.blocks.length;
    }
    const deleteBlockCount = nextPageInx - blockIndex;

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, deleteBlockCount);
    this.reevaluateBlockPositions();
  }

  /**
    * Adds a header block above the selected block
    * @param blockId - The block id to add the text block above
    */
  addHeaderBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);

    const newBlock: PracticeTypes.ReportBlock = {
      id: uuid(),
      type: 'header',
      position: blockPosition,
      content: '',
    };

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 0, newBlock);

    this.editBlock = newBlock.id;
  }

  /**
    * Adds a text block above the selected block
    * @param blockId - The block id to add the text block above
    */
  addTextBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const { blockIndex, blockPosition } = this.getBlockPositionAndIndex(blockId);

    this.incrementBlockPositions(blockIndex);

    const newBlock: PracticeTypes.ReportBlock = {
      id: uuid(),
      type: 'text',
      position: blockPosition,
      content: '',
    };

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 0, newBlock);

    this.editBlock = newBlock.id;
  }

  deleteActiveBlock() {
    const activeBlock = this.editBlock;
    if (!activeBlock) {
      return;
    }
    this.editBlock = null;
    this.deleteBlock(activeBlock);
  }

  deleteBlock(blockId: PracticeTypes.ReportBlock['id']) {
    if (!this.editedPerformanceReportTemplate) {
      this.prepEditReportTemplate();
    }

    const blockIndex = this.editedPerformanceReportTemplate.blocks.findIndex((block) => block.id === blockId);
    if (blockIndex === -1) {
      return;
    }

    this.editedPerformanceReportTemplate.blocks.splice(blockIndex, 1);
    this.reevaluateBlockPositions();
  }

  async fetchPerformanceReportTemplates() {
    const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${this.rootStore.practiceStore.id}/reportTemplates`;
    GET({
      url,
      rootStore: this.rootStore,
    }).then((response) => {
      if (response) {
        const reportTemplates = new Map<string, PracticeTypes.ReportPage>();
        response.forEach((reportTemplate: PracticeTypes.ReportPage) => {
          reportTemplates.set(reportTemplate.templateId, reportTemplate);
        });
        this.performanceReportTemplates = reportTemplates;
      }
    });
  }

  async fetchPerformanceReportTemplate(templateId: string) {
    const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${this.rootStore.practiceStore.id}/reportTemplates/${templateId}`;
    GET({
      url,
      rootStore: this.rootStore,
    }).then((response) => {
      if (response) {
        this.performanceReportTemplates.set(templateId, response);
      }
    });
  }

  async createPerformanceReportTemplate(reportTemplate: PracticeTypes.ReportPage) {
    const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${this.rootStore.practiceStore.id}/reportTemplates`;
    return POST({
      url,
      rootStore: this.rootStore,
      data: reportTemplate,
    })
      .then((response) => {
        if (response) {
          this.performanceReportTemplates.set(response.templateId, response);
          this.selectedPerformanceReportTemplate = response.templateId;
        }
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async updatePerformanceReportTemplate(reportTemplate: PracticeTypes.ReportPage) {
    const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${this.rootStore.practiceStore.id}/reportTemplates/${reportTemplate.templateId}`;
    return PUT({
      url,
      rootStore: this.rootStore,
      data: reportTemplate,
    });
  }

  async deletePerformanceReportTemplate(templateId: string) {
    const url = `${process.env.REACT_APP_BUSINESS_SERVICE_ENDPOINT}/business/${this.rootStore.practiceStore.id}/reportTemplates/${templateId}`;
    DELETE({
      url,
      rootStore: this.rootStore,
    }).then((response) => {
      if (response) {
        this.performanceReportTemplates.delete(templateId);
      }
    });
  }

  breakReportByPages() {
    const pages = [];
    let page = [];
    this.selectedPerformanceReportBlocks.forEach((block, index) => {
      if (block.type === 'page' && index === 0) {
        return;
      }
      if (block.type === 'page') {
        pages.push(page);
        page = [];
      } else {
        page.push(block);
      }
    });
    pages.push(page);
    return pages;
  }

  generateReport = async () => {
    const reportPages = this.breakReportByPages();
    const reportSections = reportPages.map((page) => (
      {
        properties: {
          type: SectionType.NEXT_PAGE,
        },
        children: page.flatMap((block) => {
          let children;
          switch (block.type) {
            case 'header':
              return block.content.blocks.flatMap((rawBlock) => {
                children = generateStyledChildren(rawBlock, block?.content?.entityMap);
                return new Paragraph({
                  children,
                  heading: HeadingLevel.TITLE,
                  alignment: rawBlock?.data?.['text-align'] || 'left',
                  spacing: { after: 200 },
                });
              });
            case 'text':
              if (!block.content) {
                return new Paragraph({ text: '' });
              }
              return block.content.blocks.flatMap((rawBlock, index, arr) => {
                children = generateStyledChildren(rawBlock, block?.content?.entityMap);
                const blockOptions: any = {
                  children,
                  spacing: { after: 200 },
                  alignment: rawBlock?.data?.['text-align'] || 'left',
                };
                switch (rawBlock.type) {
                  case 'title':
                    blockOptions.heading = HeadingLevel.TITLE;
                    break;
                  case 'header-one':
                    blockOptions.heading = HeadingLevel.HEADING_1;
                    break;
                  case 'header-two':
                    blockOptions.heading = HeadingLevel.HEADING_2;
                    break;
                  case 'header-three':
                    blockOptions.heading = HeadingLevel.HEADING_3;
                    break;
                  case 'header-four':
                    blockOptions.heading = HeadingLevel.HEADING_4;
                    break;
                  case 'header-five':
                    blockOptions.heading = HeadingLevel.HEADING_5;
                    break;
                  case 'header-six':
                    blockOptions.heading = HeadingLevel.HEADING_6;
                    break;
                  case 'ordered-list-item':
                    blockOptions.numbering = { reference: 'block-numbering', level: rawBlock.depth };
                    if (arr?.[index + 1]?.type === 'ordered-list-item') {
                      delete blockOptions.spacing;
                    }
                    break;
                  case 'unordered-list-item':
                    blockOptions.bullet = { level: rawBlock.depth };
                    if (arr?.[index + 1]?.type === 'unordered-list-item') {
                      delete blockOptions.spacing;
                    }
                    break;
                  default:
                    break;
                }

                return new Paragraph(blockOptions);
              });
            default:
              return new Paragraph({ text: block?.content || '' });
          }
        })
      }
    ));

    const doc = new DocXDoc({ sections: reportSections, ...DOCX_CONFIG });

    return Packer.toBlob(doc)
      .then((blob) => {
        const granularity = this.rootStore.timePeriodStore.periodGranularity;
        const period = this.rootStore.timePeriodStore.profitabilityPeriodSelected;
        fileDownload(blob, `${this.selectedPerformanceReport.templateName} - ${capitalizeString(granularity, true)} for ${capitalizeString(period, true)}.docx`);
      })
      .then(() => {
        Notification({ type: 'success', title: 'Report generated successfully' });
      })
      .catch((error) => {
        handleError(error);
        Notification({ type: 'error', title: 'Failed to generate report' });
      });
  };

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }
}
