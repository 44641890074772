import * as React from 'react';

type Props = {
  data: string,
  link: string,
  index: number,
  alignment: string,
  size: string,
  cellStyle: string,
}

const LinkCell = ({ data, link, index, alignment, size, cellStyle }: Props) => {
  function openTab(event, tabLink) {
    window.open(tabLink);
    event.preventDefault();
  }

  return (
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    <div
      key={index}
      onClick={(e) => openTab(e, link)}
      className={`mt-2 ${cellStyle} ${alignment} ${size}`}
      style={{ cursor: 'pointer', color: '#4263EA' }}
      role='cell'
    >
      {data}
    </div>
  );
};

export default LinkCell;
